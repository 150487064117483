import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'

export default () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          year
        }
      }
    }
  `)
  return (
    <footer className="text-center">
      <div className="social-media-links">
        <a
          href="https://www.facebook.com"
          className="facebook social"
          target="_blank"
        >
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
        <a
          href="https://www.twitter.com"
          className="twitter social"
          target="_blank"
        >
          <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
        <a
          href="https://www.instagram.com"
          className="instagram social"
          target="_blank"
        >
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
      </div>
      <div className="container mx-auto p-5">
        &copy; {site.siteMetadata.year} crowdsupply.us
        <div>
          <Link
            className="
              text-primary
              block md:inline-block mt-1
              no-underline capitalize font-semibold
              transition duration-300 ease-in-out hover:text-purple-50"
            to="/terms-conditions"
          >
            Terms and Conditions
          </Link>
        </div>
      </div>
    </footer>
  )
}
