import React, { useEffect, useContext, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import 'aos/dist/aos.css'
import MAINLAYOUT from '../components/layouts/mainLayout.jsx'
import { GlobalAuthContext } from '../context/GlobalContextProvider'
import LOGINREGISTER from '../components/loginRegister/loginRegister.jsx'
import FOOTER from '../components/footer/footer.jsx'
import { navigate } from 'gatsby'

export default () => {
  const { getAuthenticatedUser } = useContext(GlobalAuthContext)
  const [isLoading, setLoading] = useState(true)
  let AOS

  useEffect(() => {
    const AOS = require('aos')
    AOS.init({
      once: true,
    })
    getAuthenticatedUser()
      .then(() => {
        navigate('/search', { state: { enableGrid: false } })
      })
      .catch(() => {
        navigate('/')
      })
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh()
    }
    loadingRequest().then(() => {
      setLoading(false)
    })
  })

  const loadingRequest = () => {
    return new Promise(resolve => setTimeout(() => resolve(), 2500))
  }

  const {
    realTimeData,
    takePicture,
    pin,
    logoGray,
    logoLime,
  } = useStaticQuery(graphql`
    query {
      realTimeData: file(relativePath: { eq: "img/chat.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      takePicture: file(relativePath: { eq: "img/selfie.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pin: file(relativePath: { eq: "img/pin.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoGray: file(relativePath: { eq: "img/logo_gray.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoLime: file(relativePath: { eq: "img/logo_lime.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return isLoading ? (
    <div className="bg-teal absolute z-50 w-screen h-screen flex items-center">
      <div className="mx-auto" style={{ width: '250px' }}>
        <Img fluid={logoLime.childImageSharp.fluid} />
      </div>
    </div>
  ) : (
    <MAINLAYOUT>
      <div className="fixed h-full w-screen bottom-0 homeBg z-0"></div>
      <div className="mx-auto max-w-loginFormContainer">
        <div className="mx-auto pt-6 block w-mainLogo">
          <Img fluid={logoGray.childImageSharp.fluid} />
        </div>
        <LOGINREGISTER />
      </div>
      <FOOTER />
    </MAINLAYOUT>
  )
}
