import React, { useContext, useState } from 'react'
import { navigate } from 'gatsby'
import { GlobalAuthContext } from '../../context/GlobalContextProvider'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Auth } from 'aws-amplify'

const loginregister = () => {
  const {
    authenticate,
    signUp,
    forgotPassword,
    forgotPasswordSubmit,
  } = useContext(GlobalAuthContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [loginmsg, setLoginmsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingmsg, setLoadingMsg] = useState('')
  const [isForgotPasswordEnabled, setIsForgotPasswordEnabled] = useState(false)
  const [enableResetPasswordView, setEnableResetPasswordView] = useState(false)

  let LoginAndSignup, ResetPassword

  const _signin = async event => {
    event.preventDefault()
    setLoadingMsg('checking credentials')
    setLoading(true)
    if (email === '' || password === '') {
      setLoading(false)
      setLoginmsg('you must enter all the fields!')
    } else {
      authenticate(email, password)
        .then(() => navigate('/search', { state: { enableGrid: false } }))
        .catch(err => {
          setLoginmsg(err.message)
          setLoading(false)
        })
    }
  }

  const _registerUser = async event => {
    event.preventDefault()
    setLoadingMsg('Creating account.')
    setLoading(true)
    if (email === '' || password === '') {
      setLoading(false)
      setLoginmsg('you must enter all the fields!')
    } else {
      signUp(email, password)
        .then(data => {
          setLoginmsg('A verification emai has been sent.')
          setLoading(false)
        })
        .catch(err => {
          setLoginmsg(err.message)
          setLoading(false)
        })
    }
  }

  const _forgotPassword = async event => {
    event.preventDefault()
    setLoading(true)
    if (email === '') {
      setLoading(false)
      setLoginmsg('You must enter email!')
    } else {
      forgotPassword(email)
        .then(data => {
          setLoginmsg('A verification code has been sent.')
          setLoading(false)
          setEnableResetPasswordView(true)
        })
        .catch(err => {
          setLoginmsg(err.message)
          setLoading(false)
          setEnableResetPasswordView(false)
        })
    }
  }

  const _forgotPasswordSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    if (email && password && verificationCode) {
      forgotPasswordSubmit(email, password, verificationCode)
        .then(data => {
          setLoginmsg('Password reset succesfully')
          setIsForgotPasswordEnabled(false)
          setEnableResetPasswordView(false)
          setLoading(false)
        })
        .catch(err => {
          setLoginmsg(err.message)
          setLoading(false)
        })
    } else {
      setLoading(false)
      setLoginmsg('You must enter all the fields!')
    }
  }

  function toggleForgetPasswordView() {
    setIsForgotPasswordEnabled(!isForgotPasswordEnabled)
  }

  if (!enableResetPasswordView) {
    LoginAndSignup = (
      <form
        className="bg-gray-100 rounded px-8 pt-6 pb-8 mb-4 sm:shadow-md"
        key="loginDiv"
      >
        <div className="mb-4">
          <input
            className="inputStyle shadow outline-none rounded-inputs w-full py-3 px-3 leading-tight focus:outline-none"
            type="text"
            value={email}
            key="email"
            onChange={event => setEmail(event.target.value)}
            placeholder="email"
          />
        </div>
        {!isForgotPasswordEnabled ? (
          <div className="mb-1">
            <input
              className="inputStyle shadow appearance-none rounded-inputs w-full py-3 px-3 mb-3 leading-tight focus:outline-none"
              type="password"
              value={password}
              key="password1"
              onChange={event => setPassword(event.target.value)}
              placeholder="password"
            />
            <p className="text-red-100 text-xs font-semibold italic">
              {loading ? loadingmsg : loginmsg}
            </p>
          </div>
        ) : (
          <p className="text-red-100 text-xs font-semibold italic">
            {loading ? loadingmsg : loginmsg}
          </p>
        )}
        <div className="grid grid-cols-1 gap-3 items-center justify-between">
          {isForgotPasswordEnabled ? (
            <button
              className="mx-auto w-full shadow  bg-teal text-light outline-none py-2 px-4 w-iconButton
            text-white font-bold rounded-inputs
            focus:outline-none
            transition-colors duration-300 hover:bg-green-300"
              type="submit"
              onClick={_forgotPassword}
            >
              Send verification code
            </button>
          ) : null}
          {!isForgotPasswordEnabled ? (
            <div className="items-center justify-between">
              <div
                className="font-semibold underline text-xs text-gray-200 cursor-pointer forgot-pswd-toggle text-center"
                onClick={toggleForgetPasswordView}
              >
                forgot or reset password
              </div>
              <button
                className="mx-auto w-full bg-teal text-light outline-none py-3 px-4 w-iconButton
          text-white font-bold rounded-inputs
          focus:outline-none mb-3 mt-5
          transition-colors duration-300 hover:bg-green-300"
                type="submit"
                onClick={_signin}
              >
                Login
              </button>
              <button
                className="mx-auto w-full bg-white text-teal outline-none py-3 px-4 w-iconButton
          text-white font-bold rounded-inputs
          focus:outline-none
          transition-colors duration-300 hover:bg-gray-100 hover:text-darkGray"
                type="submit"
                onClick={_registerUser}
              >
                Signup
              </button>
            </div>
          ) : null}
        </div>
        <div className="mb-2 grid grid-cols-2 gap-5 text-center">
          {/*  <div className="font-semibold underline text-xs text-gray-200 cursor-pointer">
        forgot email?
      </div> */}

          {isForgotPasswordEnabled ? (
            <div
              className="font-semibold underline text-xs text-gray-200 cursor-pointer forgot-pswd-toggle"
              onClick={toggleForgetPasswordView}
            >
              Login
            </div>
          ) : null}
        </div>
      </form>
    )
  } else {
    ResetPassword = (
      <form
        className="bg-gray-100 shadow-md rounded px-8 pt-6 pb-8 mb-4"
        key="resetdiv"
      >
        <div className="mb-4">
          <div className="block text-sm font-bold mb-2">Email</div>
          <input
            className="shadow outline-none rounded-inputs w-full py-2 px-3 leading-tight focus:outline-none"
            type="text"
            value={email}
            onChange={event => setEmail(event.target.value)}
            placeholder="Email"
          />
        </div>
        <div className="mb-4">
          <div className="block text-sm font-bold mb-2">New password</div>
          <input
            className="shadow outline-none rounded-inputs w-full py-2 px-3 leading-tight focus:outline-none"
            type="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
            placeholder="******************"
          />
        </div>
        <div className="mb-1">
          <div className="block text-sm font-bold mb-2">Verification code</div>
          <input
            className="shadow appearance-none rounded-inputs w-full py-2 px-3 mb-3 leading-tight focus:outline-none"
            type="text"
            value={verificationCode}
            onChange={event => setVerificationCode(event.target.value)}
            placeholder="Verification code"
          />
          <p className="text-red-100 text-xs font-semibold italic">
            {loading ? loadingmsg : loginmsg}
          </p>
        </div>
        <button
          className="mx-auto w-full shadow  bg-teal text-light outline-none py-2 px-4 w-iconButton
          text-white font-bold rounded-inputs
          focus:outline-none
          transition-colors duration-300 hover:bg-green-300"
          type="button"
          onClick={_forgotPasswordSubmit}
        >
          Reset password
        </button>
      </form>
    )
  }

  return (
    <div className="login-container">
      <div className="w-full max-w-xs mx-auto" key="firstDiv">
        {!enableResetPasswordView && [LoginAndSignup]}
        {enableResetPasswordView && [ResetPassword]}
      </div>
      <div className="divider">
        <p className="or">OR</p>
      </div>
      <div className="social-media">
        <button
          className="google-sign-in btn"
          onClick={() => Auth.federatedSignIn({ provider: 'Google' })}
        >
          <FontAwesomeIcon icon={faGoogle} className="google social" /> Sign in
          with Google
        </button>
        <button
          className="fb-sign-in btn"
          onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })}
        >
          <FontAwesomeIcon icon={faFacebook} className="facebook social" /> Sign
          in with Facebook
        </button>
      </div>
    </div>
  )
}

export default loginregister
